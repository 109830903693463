/* BlackEcco Site */

//init Google Maps
var map;
function initMap() {
		map = new google.maps.Map(document.getElementById('map'), {
		zoom: 4,
		center: new google.maps.LatLng(27.38, -100.95),
		mapTypeId: 'roadmap',
		styles: [{
			stylers: [{
			 	saturation: -100
			}]
		}]
	});

	var iconBase = '/user/themes/blackecco/images/icons/';
	var icons = {
		mark: {
			icon: iconBase + 'pointeerest.svg'
		},
	};

	var features = [
		{
            position: new google.maps.LatLng(19.355884, -99.156377),
            type: 'mark'
		}, {
            position: new google.maps.LatLng(18.014618, -92.952412),
            type: 'mark'
		}, {
            position: new google.maps.LatLng(36.219160, -115.288338),
            type: 'mark'
		}
	];


	features.forEach(function(feature) {
		var marker = new google.maps.Marker({
			position: feature.position,
			icon: icons[feature.type].icon,
			map: map
		});
	});
}

(function($) {

	$(document).ready(function(){

		// Init Animations
		var $animFade = $('#nuestra-historia-content .row > div, #marcas .row > .cat, .entry-node');
		$animFade.addClass('wow fadein');

		var $animDelay = $('.parallax-banner h2, #fundadores .cel-image, .alianzas-logos > div, #mision-vision-content .row > div, #sectores .justify-content-center > div, .direcciones > div')
		$animDelay.addClass('wow fadein');
		$animDelay.eq(0).attr('data-wow-delay','0.1s');
		$animDelay.eq(1).attr('data-wow-delay','0.5s');
		$animDelay.eq(2).attr('data-wow-delay','0.9s');
		$animDelay.eq(3).attr('data-wow-delay','1.4s');
		$animDelay.eq(4).attr('data-wow-delay','1.9s');
		$animDelay.eq(5).attr('data-wow-delay','2.4s');
		$animDelay.eq(6).attr('data-wow-delay','2.9s');
		$animDelay.eq(7).attr('data-wow-delay','3.4s');
		$animDelay.eq(8).attr('data-wow-delay','3.9s');
		$animDelay.eq(9).attr('data-wow-delay','4.4s');
		$animDelay.eq(10).attr('data-wow-delay','4.9s');
		$animDelay.eq(11).attr('data-wow-delay','5.4s');

		wow = new WOW({
			mobile: false
		});
		wow.init();

		// check if #google-maps exist, create script tag
		if (document.getElementById("google-maps")) {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.setAttribute('defer','');
			script.setAttribute('async','');
			script.src = 'https://maps.googleapis.com/maps/api/js?' + 'key=AIzaSyBIFngfaZCPtLvgJnbfoOgqkqHCi4RKzos&'+'callback=initMap';
			document.body.appendChild(script);
		}

		// Nuestra Historia responsive reorder cols
		var $e = $('#nuestra-historia-content .row');
		for (var i=0; i < $e.length; i+=2) {$e.eq(i).addClass('odd'); };

		// mobile menu
		$('#main-menu').slb_mobile_drop_menu();

		// carousel-slider swiper
		$('.carousel .swiper-container').each(function(i) {
			var mySwiper = new Swiper ($('.carousel .swiper-container')[i], {

				// Navigation arrows
				nextButton: $('.carousel .swiper-button-next')[i],
				prevButton: $('.carousel .swiper-button-prev')[i],

				// Optional parameters
				cssWidthAndHeight: true,
				slidesPerView: 5,
				visibilityFullFit: true,
				autoResize: false,
				breakpoints: {
					1024: {
						slidesPerView: 4,
						spaceBetween: 40,
					},
					768: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
					640: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					575: {
						slidesPerView: 1,
						spaceBetween: 10,
					}
				}
			});
		});

		// homepage swiper
		if($('#encabezado .swiper-container').length) {
			var homeswiper = new Swiper('#encabezado .swiper-container', {
				nextButton: '#encabezado .swiper-button-next',
				prevButton: '#encabezado .swiper-button-prev',
				pagination: '#encabezado .swiper-pagination',
				paginationClickable: true,
			});
		}

		// product swiper
		if($('#product-images').length) {
			var productswiper = new Swiper('#product-images', {
				onlyExternal: true
			});

			var set_thumb = function()
			{
				var target = productswiper.realIndex;
				$('#product-image-thumbs a').removeClass('active');
				$('#product-image-thumb-' + target).addClass('active');
			};


			$('#product-image-thumbs a').click(function(e)
			{
				var target = $(this).attr('data-num');
				productswiper.slideTo(target);
			});


			productswiper.on('onSlideChangeStart', set_thumb);
			set_thumb();
		}

		// entry swiper
		if($('#entry-images').length) {
			var entryswiper = new Swiper('#entry-images', {
				onlyExternal: true
			});

			var set_thumb = function()
			{
				var target = entryswiper.realIndex;
				$('#entry-image-thumbs a').removeClass('active');
				$('#entry-image-thumb-' + target).addClass('active');
			};


			$('#entry-image-thumbs a').click(function(e)
			{
				var target = $(this).attr('data-num');
				entryswiper.slideTo(target);
			});


			entryswiper.on('onSlideChangeStart', set_thumb);
			set_thumb();
		}

		// Parallax Banner

		$('#parallax-banner').each(function(){
			$(this).attr('data-img-width', '1600').attr('data-img-height', '1064').attr('data-diff', '100');
		});


		// Home Counter
	    $('.counter').each(function() {
			var $this = $(this),
			countTo = $this.attr('data-count');

			$({ countNum: $this.text()}).animate({
				countNum: countTo
			},

			{
				duration: 5000,
				easing:'linear',
				step: function() {
					//var num = ("0" + Math.floor(this.countNum))
					var num = padNumber(Math.floor(this.countNum));
					$this.text(num);
			},
				complete: function() {
					$this.text(num);
				}
			});
		});

		function padNumber(num){
			return num < 10 ? '0'+num : num;
		}

	$(window).scroll(function(){
		$(".mouse-icon").css("opacity", 1 - $(window).scrollTop() / 250);
	});


		/* detect touch */
		if("ontouchstart" in window){
			document.documentElement.className = document.documentElement.className + " touch";
		}
		if(!$("html").hasClass("touch")){
			/* background fix */
			$("#parallax-banner").css("background-attachment", "fixed");
		}

		/* fix vertical when not overflow
		call fullscreenFix() if .fullscreen content changes */
		function fullscreenFix(){
			var h = $('body').height();
			// set .fullscreen height
			$(".content-b").each(function(i){
				if($(this).innerHeight() > h){ $(this).closest(".fullscreen").addClass("overflow");
				}
			});
		}
		$(window).resize(fullscreenFix);
		fullscreenFix();

		/* resize background images */
		function backgroundResize(){
			var windowH = $(window).height();
			$(".background").each(function(i){
				var path = $(this);
				// variables
				var contW = path.width();
				var contH = path.height();
				var imgW = path.attr("data-img-width");
				var imgH = path.attr("data-img-height");
				var ratio = imgW / imgH;
				// overflowing difference
				var diff = parseFloat(path.attr("data-diff"));
				diff = diff ? diff : 0;
				// remaining height to have fullscreen image only on parallax
				var remainingH = 0;
				if(path.hasId("parallax-banner") && !$("html").hasClass("touch")){
					var maxH = contH > windowH ? contH : windowH;
					remainingH = windowH - contH;
				}
				// set img values depending on cont
				imgH = contH + remainingH + diff;
				imgW = imgH * ratio;
				// fix when too large
				if(contW > imgW){
					imgW = contW;
					imgH = imgW / ratio;
				}
				//
				path.data("resized-imgW", imgW);
				path.data("resized-imgH", imgH);
				path.css("background-size", imgW + "px " + imgH + "px");
			});
		}
		$(window).resize(backgroundResize);
		$(window).focus(backgroundResize);
		backgroundResize();

		/* set parallax background-position */
		function parallaxPosition(e){
			var heightWindow = $(window).height();
			var topWindow = $(window).scrollTop();
			var bottomWindow = topWindow + heightWindow;
			var currentWindow = (topWindow + bottomWindow) / 2;
			$("#parallax-banner").each(function(i){
				var path = $(this);
				var height = path.height();
				var top = path.offset().top;
				var bottom = top + height;
				// only when in range
				if(bottomWindow > top && topWindow < bottom){
					var imgW = path.data("resized-imgW");
					var imgH = path.data("resized-imgH");
					// min when image touch top of window
					var min = 0;
					// max when image touch bottom of window
					var max = - imgH + heightWindow;
					// overflow changes parallax
					var overflowH = height < heightWindow ? imgH - height : imgH - heightWindow; // fix height on overflow
					top = top - overflowH;
					bottom = bottom + overflowH;
					// value with linear interpolation
					var value = min + (max - min) * (currentWindow - top) / (bottom - top);
					// set background-position
					var orizontalPosition = path.attr("data-oriz-pos");
					orizontalPosition = orizontalPosition ? orizontalPosition : "50%";
					$(this).css("background-position", orizontalPosition + " " + value + "px");
				}
			});
		}
		if(!$("html").hasClass("touch")){
			$(window).resize(parallaxPosition);
			//$(window).focus(parallaxPosition);
			$(window).scroll(parallaxPosition);
			parallaxPosition();
		}

	});

}(jQuery));
